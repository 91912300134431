/* @import url('https://fonts.googleapis.com/css2?family=Fuggles&family=Roboto:ital,wght@0,300;0,400;0,700;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto%20Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CPoppins%3A800%2C200%2C400%2C500%7CRoboto%3A400&amp;display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
     margin: 0;
     /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
     font-family: "Roboto";
     font-size: 16px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.home-gradient-bg {
     width: 100%;
     background: linear-gradient(#108B4AE5, #108B4AE5), url(./assets/images/Offa-bg.png) no-repeat;
     background-size: cover;
}